import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import {
  AccountOrdersResponse,
  AllOrdersResponse,
  GetAllCashOrdersResponse,
  OrderByIdResponse,
} from '@core/models/admin/orders';
import { Pagination } from '@core/models/pagination.model';
import { Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  http = inject(HttpClient);
  BASE_URL = environment.ADMIN_BASE_URL + '/v1/order';
  PORTFOLIO_BASE_URL = environment.ADMIN_BASE_URL + '/v1/portfolio';

  private loadingService = inject(LoadingService);

  ordersLoadingStates = {
    getOrdersByAccountId: 'getOrdersByAccountId',
  };

  constructor() {}

  createOrder(orderInfo: any): Observable<any> {
    return this.http.post(`${this.BASE_URL}/create-order`, orderInfo);
  }

  updateStatus(data: { id: string; status: string }): Observable<any> {
    return this.http.post(`${this.BASE_URL}/change-order-status`, data);
  }

  viewOrderInfo(orderId: string) {
    return this.http.get(`${this.BASE_URL}/view-order-info/${orderId}`);
  }

  filterOrders(queryParams: string): Observable<AllOrdersResponse> {
    return this.http.get<AllOrdersResponse>(
      `${this.BASE_URL}/order-filter?${queryParams}`
    );
  }

  viewOrderList(
    data: Pagination = new Pagination()
  ): Observable<AllOrdersResponse> {
    const uniqueStateUrl = 'get-all-order';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<AllOrdersResponse>(
        `${this.BASE_URL}/get-all-order?take=${data.size}&skip=${data.skip}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getOrderById(orderId: string): Observable<OrderByIdResponse> {
    const uniqueStateUrl = 'get-order-by-id';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<OrderByIdResponse>(
        `${this.BASE_URL}/get-order-by-id?order_id=${orderId}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  filterOrdersByAccountID(queryParams: string): Observable<AccountOrdersResponse> {
    return this.http.get<AccountOrdersResponse>(
      `${this.BASE_URL}/filter-order-by-account_id?${queryParams}`
    );
  }
  getOrdersByAccountId(
    accountId: string,
    page?: Pagination
  ): Observable<AccountOrdersResponse> {
    this.loadingService.setLoading(
      this.ordersLoadingStates.getOrdersByAccountId,
      true
    );
    let path = `get-order-by-account?account_id=${accountId}`;
    if (page) {
      path = `${path}&take=${page.size}&skip=${page.skip}`;
    }
    return this.http
      .get<AccountOrdersResponse>(`${this.BASE_URL}/${path}`)
      .pipe(
        finalize(() =>
          this.loadingService.setLoading(
            this.ordersLoadingStates.getOrdersByAccountId,
            false
          )
        )
      );
  }

  // filterOrders(payload: {
  //   order_start_date?: string;
  //   order_end_date?: string;
  //   category?: string;
  //   product_id?: string;
  //   order_type?: string;
  //   order_status?: string;
  //   amount?: number;
  //   account_id?: string;
  // }): Observable<unknown> {
  //   return this.http.post(`${this.BASE_URL}/order-filter`, payload);
  // }

  getOrderListCount(status: string = 'terminated') {
    return this.http.get(this.BASE_URL + `/order-meta-data?status=${status}`);
  }
  //order-meta-data?type=liquidate
  //order-meta-data?type=invest
  // order-meta-data?status=initiated&type=liquidate

  updateOrder(orderId: string, status: string) {
    const payload = {
      status,
      order_id: orderId,
    };
    return this.http.post(this.BASE_URL + `/change-order-status`, payload);
  }
  getOrderListCountByType(type: string) {
    return this.http.get(`${this.BASE_URL}/order-meta-data?type=${type}`);
  }

  getAllCashOrders(): Observable<GetAllCashOrdersResponse> {
    const uniqueStateUrl = 'get-all-cash-order';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<GetAllCashOrdersResponse>(
        `${this.BASE_URL}/get-all-cash-order?skip=1&take=1`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
}
